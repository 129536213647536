/* eslint-disable func-names */
/* eslint-disable no-extend-native */
import React from 'react';
import { DragonKit, NavHeader, NavBar, Footer, LinkTo, QuickNav } from '@dragonchain-dev/dragon-kit';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import footerLinks from '../../config/links.footer.json';
import appLinks from '../../config/links.navigation.json';
import socialLinks from '../../config/links.social.json';
import Logo from '../assets/images/icons/dragonchain.svg';
import '../assets/styles/main.scss';

Array.prototype.srcFluid = function(src) {
  const image = this.filter(file => file.node.relativePath === src)[0];
  return image ? image.node.childImageSharp.fluid : undefined;
};

Array.prototype.srcFixed = function(src) {
  const image = this.filter(file => file.node.relativePath === src)[0];
  return image ? image.node.childImageSharp.fixed : undefined;
};

const Layout = ({ children }) => {
  const { jojo } = useStaticQuery(
    graphql`
      {
        jojo: file(relativePath: { eq: "layout/jojo.png" }) {
          childImageSharp {
            fixed(width: 90, height: 90) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `,
  );

  const logo = {
    fixed: jojo.childImageSharp.fixed,
    fadeIn: false,
    loading: 'eager',
    alt: 'jojo',
    className: 'logo',
  };

  return (
    <DragonKit Link={Link} theme="dark" layout="full" Img={Img}>
      <QuickNav entity="Dragonchain" appLinks={appLinks} ecosystemLinks={footerLinks} />
      {/* <Notice type="banner">
        Did Open Source Money bring you here?{' '}
        <Link to="/open-source-money/">Learn more about Dragonchain</Link>
      </Notice> */}

      <NavHeader Logo={Logo}>
        <NavBar links={appLinks} />
      </NavHeader>

      <main>{children}</main>
      <Footer
        logo={logo}
        tagline="America's Blockchain"
        entity="Dragonchain"
        links={footerLinks}
        social={socialLinks}
      >
        <p>
          The comments, views, and opinions expressed are those of the author and do not necessarily reflect
          the official policy or position of the Walt Disney Company, Disney Connected and Advanced
          Technologies, or any affiliated companies.{' '}
          <LinkTo type="route" url="/terms">
            Terms of service.
          </LinkTo>
        </p>
      </Footer>
    </DragonKit>
  );
};

export default Layout;
