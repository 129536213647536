import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
      }
    }
  }
`;

const schemaArticle = (title, primaryCategory, publishDate, description, image, url) => (
  <Helmet>
    <meta property="article:published_time" content={publishDate} />
    <meta property="article:modified_time" content={publishDate} />
    <meta property="og:tag" content={primaryCategory} />
    <meta property="article:section" content="Blockchain" />
    <script type="application/ld+json">
      {`{
  "@context": "https://schema.org",
  "@type": "Article",
  "author": {
    "@type": "Organization",
    "name": "Dragonchain"
  },
  "creator": {
    "@type": "Organization",
    "name": "Dragonchain"
  },
  "publisher": {
    "@type": "Organization",
    "name": "Dragonchain",
    "logo": {
      "@type": "ImageObject",
      "url": "https://dragonchain.com/static/media/dragonchain-wordmark.png"
    }
  },
  "datePublished": "${publishDate}",
  "dateModified": "${publishDate}",
  "description": "${description}",
  "headline": "${title}",
  "url": "${url}",
  "name": "${title}",
  "image": {
    "@type": "ImageObject",
    "url": "${image}"
  },
  "mainEntityOfPage": "${url}"
}`}
    </script>
  </Helmet>
);

const PageMeta = ({
  description,
  image,
  pathname,
  title,
  publishDate,
  category,
  article = false,
  isCrawlable = true,
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { defaultTitle, titleTemplate, defaultDescription, siteUrl },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: image && image.src ? `${siteUrl}${image.src}` : null,
        url: `${siteUrl}${pathname || '/'}`,
      };

      return (
        <>
          {article && schemaArticle(seo.title, category, publishDate, seo.description, seo.image, seo.url)}
          <Helmet
            key="page-meta"
            title={seo.title}
            titleTemplate={titleTemplate}
            meta={[
              {
                name: 'description',
                content: seo.description,
              },
              {
                property: 'og:title',
                content: seo.title,
              },
              {
                property: 'og:description',
                content: seo.description,
              },
              {
                name: 'twitter:creator',
                content: '@dragonchain',
              },
              {
                name: 'twitter:title',
                content: seo.title,
              },
              {
                name: 'twitter:description',
                content: seo.description,
              },
            ]
              .concat(
                seo.image
                  ? [
                      {
                        property: 'og:image',
                        content: seo.image,
                      },
                      {
                        property: 'og:image:width',
                        content: image.width,
                      },
                      {
                        property: 'og:image:height',
                        content: image.height,
                      },
                      {
                        name: 'twitter:card',
                        content: 'summary_large_image',
                      },
                    ]
                  : [
                      {
                        name: 'twitter:card',
                        content: 'summary',
                      },
                    ],
              )
              .concat(
                !isCrawlable && {
                  name: 'robots',
                  content: 'noindex',
                },
              )}
          />
        </>
      );
    }}
  />
);

export default PageMeta;
